<template>
  <div class="section">
    <ModalHeader
      :left-text="'Kembali'"
      :middle-text="
        $store.state.supplier.editId == null ? 'Tambah Pemasok' : 'Edit Pemasok'
      "
      :left-function="directPage"
      :is-rigt-column="true"
      :right-function="
        $store.state.supplier.editId == null ? createItem : updateItem
      "
      :right-text="'Simpan'"
    />
    <b-container class="mt-5 pt-5">
      <b-row class="justify-content-center h-100 bg-white">
        <b-col cols="12" md="6">
          <b-row>
            <!-- Nama Supplier -->
            <b-col cols="12" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-supplier"
                  >Nama Pemasok <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="v-supplier"
                  v-model="formPayload.name"
                  type="text"
                  placeholder="Nama Pemasok"
                  class="custom__input"
                />
                <small
                  v-if="
                    messages.name &&
                    Array.isArray(messages.name) &&
                    messages.name.length > 0
                  "
                  class="text-danger"
                  >{{ messages.name.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- No. Telpon Kantor -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <label for="v-telp-kantor"
                >No. Telepon <span class="text-danger">*</span></label
              >
              <b-row>
                <b-col cols="6" class="pr-0">
                  <v-select
                    v-model="formPayload.selectedCountry"
                    :label="currentLabel"
                    :options="countryList"
                    :clearable="false"
                    @keyup.stop.native="searchCountry"
                  >
                    <template
                      #selected-option="{ country_flag, dial_code_preview }"
                    >
                      <div class="d-flex align-items-center" style="gap: 6px">
                        <img
                          :src="country_flag"
                          :alt="dial_code_preview"
                          style="width: 24px; height: 24px"
                        />
                        <p class="size12 mb-0">({{ dial_code_preview }})</p>
                      </div>
                    </template>
                    <template #option="{ country_flag, dial_code_preview }">
                      <div class="d-flex align-items-center" style="gap: 8px">
                        <img
                          :src="country_flag"
                          :alt="dial_code_preview"
                          style="width: 24px; height: 24px"
                        />
                        <p class="mb-0">{{ dial_code_preview }}</p>
                      </div>
                    </template>
                  </v-select>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-input
                      id="v-telp-kantor"
                      v-model="formattedPhoneKantor"
                      type="tel"
                      placeholder="81257152456"
                      class="custom__input"
                    />
                    <small
                      v-if="
                        messages.phone_number &&
                        Array.isArray(messages.phone_number) &&
                        messages.phone_number.length > 0
                      "
                      class="text-danger"
                      >{{ messages.phone_number.join(", ") }}</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- No. NPWP -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-npwp"
                  >No. NPWP <span class="text-danger">*</span></label
                >
                <input
                  id="v-npwp"
                  type="text"
                  class="form-control custom__input"
                  @change="maskingInputNpwp"
                  @input="maskingInputNpwp"
                />
                <!-- <b-form-input
                  id="v-npwp"
                  v-model="formPayload.tax_number"
                  type="number"
                  placeholder="No. NPWP"
                  class="custom__input"
                /> -->
                <small
                  v-if="
                    messages.tax_number &&
                    Array.isArray(messages.tax_number) &&
                    messages.tax_number.length > 0
                  "
                  class="text-danger"
                  >{{ messages.tax_number.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- Alamat -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input">
              <label for="v-address"
                >Alamat <span class="text-danger">*</span></label
              >
              <quil-editor
                :payload="formPayload.address"
                @contentText="getContentText"
              />
              <!-- <b-form-textarea
                id="v-address"
                v-model="formPayload.address"
                rows="5"
                placeholder="Alamat"
                class="custom__textarea"
              /> -->
              <small
                v-if="
                  messages.address &&
                  Array.isArray(messages.address) &&
                  messages.address.length > 0
                "
                class="text-danger"
                >{{ messages.address.join(", ") }}</small
              >
            </b-col>
            <!-- Nama Sales -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-nama-sales"
                  >Nama Sales <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="v-nama-sales"
                  v-model="formPayload.sales_name"
                  type="text"
                  placeholder="Nama Sales"
                  class="custom__input"
                />
                <small
                  v-if="
                    messages.sales_name &&
                    Array.isArray(messages.sales_name) &&
                    messages.sales_name.length > 0
                  "
                  class="text-danger"
                  >{{ messages.sales_name.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- No. Telepon Sales -->
            <!-- <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-telp-sales">No. Telepon Sales <span class="text-danger">*</span></label>
                <b-form-input
                  id="v-telp-sales"
                  v-model="formPayload.sales_phone_number"
                  type="tel"
                  placeholder="No. Telepon Sales"
                  class="custom__input"
                  @input="handleSalesPhoneNumberInput"
                />
                <small
                  v-if="messages.sales_phone_number && Array.isArray(messages.sales_phone_number) && messages.sales_phone_number.length > 0"
                  class="text-danger"
                >{{ messages.sales_phone_number.join(', ') }}</small>
              </b-form-group>
            </b-col> -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <label for="v-telp-sales"
                >No. Telepon Sales <span class="text-danger">*</span></label
              >
              <b-row>
                <b-col cols="6" class="pr-0">
                  <v-select
                    v-model="formPayload.salesSelectedCountry"
                    :label="currentLabel"
                    :options="countryList"
                    :clearable="false"
                    @keyup.stop.native="searchCountry"
                  >
                    <template
                      #selected-option="{ country_flag, dial_code_preview }"
                    >
                      <div class="d-flex align-items-center" style="gap: 6px">
                        <img
                          :src="country_flag"
                          :alt="dial_code_preview"
                          style="width: 24px; height: 24px"
                        />
                        <p class="size12 mb-0">({{ dial_code_preview }})</p>
                      </div>
                    </template>
                    <template #option="{ country_flag, dial_code_preview }">
                      <div class="d-flex align-items-center" style="gap: 8px">
                        <img
                          :src="country_flag"
                          :alt="dial_code_preview"
                          style="width: 24px; height: 24px"
                        />
                        <p class="mb-0">{{ dial_code_preview }}</p>
                      </div>
                    </template>
                  </v-select>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-input
                      id="v-telp-sales"
                      v-model="formattedPhoneSales"
                      type="tel"
                      placeholder="81257152456"
                      class="custom__input"
                    />
                    <small
                      v-if="
                        messages.phone_number &&
                        Array.isArray(messages.phone_number) &&
                        messages.phone_number.length > 0
                      "
                      class="text-danger"
                      >{{ messages.phone_number.join(", ") }}</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pilih Tipe Pembayaran -->
            <b-col cols="12" class="mb-2 custom__form--input">
              <label for="v-telp-sales"
                >Pilih Tipe Pembayaran <span class="text-danger">*</span></label
              >
              <div class="d-flex">
                <b-button
                  v-for="(item, index) in listPayment"
                  :key="`type-customer-${index}`"
                  class="btn-type py-1"
                  :class="{ active: item == formPayload.payment_type }"
                  @click="formPayload.payment_type = item"
                >
                  {{ item }}
                </b-button>
              </div>
              <small
                v-if="
                  messages.payment_type &&
                  Array.isArray(messages.payment_type) &&
                  messages.payment_type.length > 0
                "
                class="text-danger"
                >{{ messages.payment_type.join(", ") }}</small
              >
            </b-col>
            <!-- Hari -->
            <b-col
              v-if="formPayload.payment_type == 'TOP'"
              cols="12"
              md="12"
              class="mb-2 custom__form--input"
            >
              <b-form-group label="Hari" label-for="v-day">
                <b-form-input
                  id="v-day"
                  v-model="formPayload.terms_of_payment_day"
                  type="number"
                  class="custom__input"
                  placeholder="--"
                />
                <small
                  v-if="
                    messages.terms_of_payment_day &&
                    Array.isArray(messages.terms_of_payment_day) &&
                    messages.terms_of_payment_day.length > 0
                  "
                  class="text-danger"
                  >{{ messages.terms_of_payment_day.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row class="pb-5" v-if="$store.state.supplier.editId !== null">
            <BankAccounts :banks="banks" :reload-data="getBankAccounts" />
          </b-row>
          <Riwayat v-if="$store.state.supplier.editId !== null" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import { mapActions } from "vuex";

import Riwayat from "@/components/Customer/RiwayatSupplier.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import BankAccounts from "@/components/Customer/Modal/Part/BankAccounts.vue";
import QuilEditor from "@/components/QuilEditor.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BankAccounts,
    ModalHeader,
    QuilEditor,
    Riwayat,
    vSelect,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    getData: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      isLoading: false,
      listPayment: ["Cash", "TOP"],
      formPayload: {
        name: "",
        phone_number: "",
        phone_number_country: "",
        tax_number: "",
        address: "",
        sales_name: "",
        sales_phone_number: "",
        sales_phone_number_country: "",
        payment_type: "",
        terms_of_payment_day: 0,
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        salesSelectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      messages: "",
      banks: [],
      searchQuery: "",
      countryList: [],
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQuery)
        ? "dial_code_value"
        : "country_name";
    },
    dialCodeCountry() {
      const selectedCountry = this.countryList.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "62";
    },
    dialCodeSalesCountry() {
      const selectedCountry = this.countryList.find(
        (country) =>
          country.country_code ===
          this.formPayload.salesSelectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "";
    },
    formattedPhoneKantor: {
      get() {
        const regex = new RegExp(`^${this.dialCodeCountry}`);
        return this.formPayload.phone_number.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.phone_number = this.dialCodeCountry + value;
      },
    },
    formattedPhoneSales: {
      get() {
        const regex = new RegExp(`^${this.dialCodeSalesCountry}`);
        return this.formPayload.sales_phone_number.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.sales_phone_number = this.dialCodeSalesCountry + value;
      },
    },
  },
  watch: {
    "formPayload.selectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.phone_number.startsWith(oldDialCode)) {
        this.formPayload.phone_number =
          newDialCode + this.formPayload.phone_number.replace(oldDialCode, "");
      }
    },
    "formPayload.salesSelectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.sales_phone_number.startsWith(oldDialCode)) {
        this.formPayload.sales_phone_number =
          newDialCode +
          this.formPayload.sales_phone_number.replace(oldDialCode, "");
      }
    },
  },
  created() {
    this.getBankAccounts();
    this.formPayload = this.$store.state.supplier.formPayload;
    // this.formPayload.payment_type = this.$store.state.supplier.formPayload.payment_type
  },
  mounted() {
    if (this.formPayload.tax_number) {
      setTimeout(() => {
        const npwp = document.getElementById("v-npwp");
        npwp.value = this.maskNumber(this.formPayload.tax_number).replaceAll(
          "#",
          ""
        );
      }, 500);
    }
    this.getCountry();
  },
  methods: {
    ...mapActions("supplier", ["postData"]),
    maskingInputNpwp() {
      const npwp = document.getElementById("v-npwp");
      npwp.value = this.maskNumber(npwp.value).replaceAll("#", "");
      this.formPayload.tax_number = npwp.value.replaceAll(" ", "");
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          this.countryList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // handlePhoneNumberInput(value) {
    //   if (!value) {
    //     this.formPayload.phone_number = "62";
    //   }
    // },
    // handleSalesPhoneNumberInput(value) {
    //   if (!value) {
    //     this.formPayload.sales_phone_number = "62";
    //   }
    // },
    maskNumber(number) {
      // Remove all non-digit characters from the input number
      var digitsOnly = number.replace(/\D/g, "");

      // Determine the number of groups
      var numGroups = Math.ceil(digitsOnly.length / 4);

      // Generate the mask
      var mask = "";
      for (var i = 0; i < numGroups; i++) {
        mask += "#### ";
      }
      mask = mask.trim();

      // Apply the mask to the number
      var maskedNumber = "";
      var digitIndex = 0;
      for (var j = 0; j < mask.length; j++) {
        var char = mask.charAt(j);
        if (char === "#" && digitIndex < digitsOnly.length) {
          maskedNumber += digitsOnly.charAt(digitIndex);
          digitIndex++;
          if (digitIndex % 4 === 0 && digitIndex !== digitsOnly.length) {
            maskedNumber += " ";
          }
        } else {
          maskedNumber += char;
        }
      }

      return maskedNumber;
    },
    getContentText(val) {
      this.formPayload.address = val;
    },
    async getBankAccounts() {
      if (this.$store.state.supplier.editId) {
        await this.$store
          .dispatch("bankAccounts/getData", {
            params: {
              supplier_uuid: this.$store.state.supplier.editId,
            },
          })
          .then((result) => {
            this.banks = result.data.data;
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    },
    clearItem() {
      this.$store.commit("supplier/setEditId", null);
      this.$store.commit("supplier/setFormPayload", {
        name: "",
        phone_number: "",
        tax_number: "",
        address: "",
        sales_name: "",
        sales_phone_number: "",
        payment_type: "",
        terms_of_payment_day: 0,
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        salesSelectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      });
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin membuat Pemasok baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (this.formPayload.payment_type !== "TOP") {
            delete this.formPayload.terms_of_payment_day;
          }
          const form = this.preparePayload();
          this.isLoading = true;
          this.postData({
            query: "",
            payload: form,
          })
            .then(() => {
              this.getData();
              successNotification(this, "Success", "Supplier berhasil di buat");
              this.clearItem();
              this.$bvModal.hide("modal-supplier");
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
              }
              this.isLoading = false;
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin mengubah data Pemasok ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (this.formPayload.payment_type !== "TOP") {
            delete this.formPayload.terms_of_payment_day;
          }

          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("supplier/postData", {
              query: `/${this.$store.state.supplier.editId}`,
              payload: form,
            })
            .then(() => {
              this.getData();
              successNotification(this, "Success", "Pemasok berhasil di ubah!");
              this.clearItem();
              this.isLoading = false;
              this.$bvModal.hide("modal-supplier");
            })
            .catch((error) => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
    preparePayload() {
      this.formPayload.selectedCountry != null
        ? (this.formPayload.phone_number_country =
            this.formPayload.selectedCountry.country_code)
        : "";
      this.formPayload.salesSelectedCountry != null
        ? (this.formPayload.sales_phone_number_country =
            this.formPayload.salesSelectedCountry.country_code)
        : "";
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key]) {
          if (
            key !== "payment_type" &&
            key !== "phone_number" &&
            key !== "sales_phone_number" &&
            key != "selectedCountry" &&
            key != "salesSelectedCountry"
          ) {
            form.append(key, this.formPayload[key]);
          } else {
            form.append(key, this.formPayload[key] === "Cash" ? "Cash" : "TOP");
          }
          if (key == "phone_number" || key == "sales_phone_number") {
            const digit1 = Number(String(this.formPayload[key]).charAt(0));
            console.log(
              digit1 === 0
                ? `62${this.formPayload[key].substring(1)}`
                : this.formPayload[key],
              " BANG"
            );
            form.append(
              key,
              digit1 === 0
                ? `62${this.formPayload[key].substring(1)}`
                : this.formPayload[key]
            );
          }
        }
      }
      return form;
    },
    directPage() {
      this.clearItem();
      this.$bvModal.hide("modal-supplier");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.bg-white.btn-secondary:focus {
  background-color: transparent !important;
}

.btn-type {
  background-color: #eff1f5 !important;
  border: 1px solid #e4e5ec !important;
  border-radius: 8px;
  margin-right: 15px;
  color: $dark !important;

  &.active {
    background-color: #ffffff !important;
    border: 1px solid #89a7e0 !important;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
    color: #2e5aac !important;
  }
}
</style>
